import { useContext, useMemo } from 'react'
import type { StorageUserData } from '@vayapin/oauth-kit'

import Context from './Context'

function useUser(): Partial<StorageUserData> {
  const contextData = useContext(Context)

  return useMemo(
    () => contextData?.userData ?? {
      currentAccount: undefined,
      accounts: [],
    },
    [contextData]
  )
}

export default useUser
