import type { OAuthKitState } from '@vayapin/oauth-kit'

import { createContext } from 'react'

export const DEFAULT_DATA: OAuthKitState = {
  authData: {},
  userData: {},
  lastActivityAt: undefined,
  lastOAuthAuthorizationCodeFlowAt: undefined,
  signedIn: false,
  accessAllowed: false,
}

// Context instance
const AuthContext = createContext(DEFAULT_DATA)
AuthContext.displayName = 'AuthContext'

// export provider/consumer
export const Provider = AuthContext.Provider
export const Consumer = AuthContext.Consumer

// Export the context
export default AuthContext
