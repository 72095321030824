import first from 'lodash/first'
import type { AccountAbilities } from '@vayapin/id-types'

import { useContext, useMemo } from 'react'
import Context from './Context'

function useAbilities(): Partial<AccountAbilities> {
  const contextData = useContext(Context)

  return useMemo(
    () => {
      const accounts = contextData?.userData?.accounts ?? []
      return first(accounts)?.abilities ?? {}
    },
    [contextData]
  )
}

export default useAbilities
