import first from 'lodash/first'
import { useContext, useMemo } from 'react'

import Context from './Context'

import type { Account } from '@vayapin/id-types'

function useAccount(): Partial<Account> {
  const contextData = useContext(Context)

  return useMemo(
    () => {
      const accounts = contextData?.userData?.accounts ?? []
      return first(accounts) ?? {}
    },
    [contextData]
  )
}

export default useAccount
