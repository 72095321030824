import type { StorageAuthData } from '@vayapin/oauth-kit'

import { useContext, useMemo } from 'react'
import Context from './Context'

function useAuthData(): Partial<StorageAuthData> {
  const contextData = useContext(Context)

  return useMemo(
    () => contextData?.authData ?? {},
    [contextData]
  )
}

export default useAuthData
