import React, {
  useCallback, useEffect, useRef, useState, PropsWithChildren
} from 'react'
import OAuthKit, { OAuthKitState, isNumGt0, OAuthError } from '@vayapin/oauth-kit'

import Config from './Config'
import { DEFAULT_DATA, Provider as ContextProvider } from './Context'
import { checkTokenTimeoutMilliseconds, debugLog } from './utils'

function OAuthKitProvider({ children }: PropsWithChildren) {
  const [data, setData] = useState<OAuthKitState>(DEFAULT_DATA)
  const remoteCheckInterval = useRef<ReturnType<typeof setTimeout>>()

  //
  // Hook called when state in OAuthKit changed
  const onOAuthKitStateChange = useCallback(async () => {
    debugLog(['Provider.onOAuthKitStateChange', OAuthKit.getState()])
    setData(OAuthKit.getState())
  }, [])

  //
  // Run remote validity check for the current stored token.
  // If there is no token available, it won't output anything
  // and will try again. If tokenRemoteCheckInterval is negative
  // it'll just return and don't do anything.
  const checkTokenRemoteValid = useCallback(async () => {
    debugLog('Provider.checkTokenRemoteValid')
    const { tokenRemoteCheckInterval } = Config.get()

    if (!isNumGt0(tokenRemoteCheckInterval)) return

    try {
      await OAuthKit.isTokenRemoteValid()
    } catch (e) {
      const err = e as OAuthError

      if ((err instanceof OAuthError) === false
          || err.reason !== 'LOCAL_TOKEN_INVALID') {
        throw err
      }
    } finally {
      if (remoteCheckInterval.current) clearTimeout(remoteCheckInterval.current)

      remoteCheckInterval.current = setTimeout(
        checkTokenRemoteValid,
        tokenRemoteCheckInterval * 1000
      )
    }
  }, [])

  //
  // Build the initial timeout for checkTokenRemoteValid based
  // on the tokenRemoteCheckInterval.
  const prepareRemoteCheckInterval = useCallback(async () => {
    debugLog('Provider.prepareRemoteCheckInterval')

    const { tokenRemoteCheckInterval } = Config.get()
    let delta = await checkTokenTimeoutMilliseconds()

    if (delta === false) delta = tokenRemoteCheckInterval * 1000
    if (remoteCheckInterval.current) clearTimeout(remoteCheckInterval.current)

    remoteCheckInterval.current = setTimeout(checkTokenRemoteValid, delta)
  }, [checkTokenRemoteValid])

  //
  // Register callbacks
  useEffect(() => {
    OAuthKit.subscribeChange(onOAuthKitStateChange)
    OAuthKit.syncState()

    onOAuthKitStateChange()
    prepareRemoteCheckInterval()

    return function cleanUp() {
      OAuthKit.unsubscribeChange(onOAuthKitStateChange)
      if (remoteCheckInterval?.current) clearTimeout(remoteCheckInterval?.current)
    }
  }, [onOAuthKitStateChange, prepareRemoteCheckInterval])

  //
  // Render
  return (
    <ContextProvider value={data}>
      {children}
    </ContextProvider>
  )
}

export default OAuthKitProvider
