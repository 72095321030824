import { useContext, useMemo } from 'react'
import Context from './Context'

function useSignedIn(): boolean {
  const contextData = useContext(Context)

  return useMemo(
    () => contextData?.signedIn,
    [contextData]
  )
}

export default useSignedIn
