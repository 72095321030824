import Config from './Config'
import { version } from './version'

import type { Config as IConfig, ConfigConfigureOptions } from './Types'

/**
 * Merges the default config with your passed configuration
 * object and saves it as current config.
 * @see {@link Config.configure}
 */
export function configure(cfg: ConfigConfigureOptions, reset = false): IConfig {
  return Config.configure(cfg, reset)
}

/**
 * Returns the current config
 */
export function getConfig(): IConfig {
  return Config.get()
}

/**
 * OAuthKitReact
 */
const OAuthKitReact = {
  configure,
  getConfig,
  version,
}
export default OAuthKitReact
