import isArray from 'lodash/isArray'

import Config from '../Config'

function debugLog(value?: any | any[]) {
  if (!Config.get().debug) return

  if (!isArray(value)) value = [value]

  console.debug(`[OAuthKitReact]: `, ...value)
}

export default debugLog
