import { useEffect, useRef, useState } from 'react'
import OAuthKit, { isPresent, OAuthError } from '@vayapin/oauth-kit'

import { debugLog } from './utils'

export type State = 'authenticating' | 'error' | 'done';

export type Error = 'authorizationCodeMissing' | 'tokenAcquisitionFailed' | 'unknownError'

export interface UseOAuthCallbackResult<T> {
  /**
   * If the authentication is loading or not
   */
  loading: boolean;

  /**
   * State of the process.
   */
  state: State;

  /**
   * Error code
   */
  error?: Error;

  /**
   * OAuthKit error
   */
  authError?: OAuthError;

  /**
   * Data that you stored via {@link OAuthKit.getAfterSignInData}
   * before being redirected to the id service.
   */
  afterSignInData?: T;
}

async function fetchToken(code: string): Promise<true> {
  await OAuthKit.fetchOAuthTokenAndData(code)
  return true
}

const DEFAULT_RESULT: UseOAuthCallbackResult<unknown> = {
  loading: true,
  state: 'authenticating',
  error: undefined,
  authError: undefined,
  afterSignInData: undefined,
}

/**
 * Handle the OAuth authorization code callback
 * to fetch new token and user data.
 * @type T The Type of your data set via {@link OAuthKit.getAfterSignInData}
 */
function useOAuthCallback<T>(
  code?: string,
): UseOAuthCallbackResult<T> {
  const [result, setResult] = useState<UseOAuthCallbackResult<T>>(
    DEFAULT_RESULT as UseOAuthCallbackResult<T>
  )
  const loading = useRef(false)

  // check code
  useEffect(() => {
    debugLog(['useOAuthCallback.useEffect', code])

    if (!isPresent(code)) {
      setResult((r) => ({
        ...r,
        loading: false,
        state: 'error',
        error: 'authorizationCodeMissing',
      }))
      return
    }

    const runFetchToken = async () => {
      if (loading.current) return

      debugLog(['useOAuthCallback.runFetchToken'])
      loading.current = true

      let tokenResult: boolean | OAuthError = false
      const result: UseOAuthCallbackResult<T> = {
        ...(DEFAULT_RESULT as UseOAuthCallbackResult<T>),
        loading: false,
      }

      try {
        tokenResult = await fetchToken(code || '')
        debugLog(['useOAuthCallback.runFetchToken - result', tokenResult])
      } catch (e) {
        debugLog(['useOAuthCallback.runFetchToken - error', e])

        if ((e instanceof OAuthError) === false) {
          throw e
        }

        const err = e as OAuthError

        if (err.reason === 'AUTHORIZATION_CODE_MISSING') {
          setResult((r) => ({
            ...r,
            state: 'error',
            error: 'authorizationCodeMissing',
            authError: err,
          }))
          loading.current = false
          return
        }

        setResult((r) => ({
          ...r,
          state: 'error',
          error: 'unknownError',
          authError: err,
        }))

        loading.current = false

        throw err
      }

      if ((tokenResult as unknown as OAuthError) instanceof OAuthError) {
        result.state = 'error'
        result.error = 'tokenAcquisitionFailed'
        result.authError = tokenResult as unknown as OAuthError
      } else {
        result.state = 'done'
        result.afterSignInData = await OAuthKit.getAfterSignInData()
      }

      setResult(result)
      loading.current = false
    }

    runFetchToken()
  }, [code, setResult])

  // Return data
  return result
}

export default useOAuthCallback
